import './UIButton.css'

export default function UIButton(props) {
    return (
        <button className="uibutton" onClick={props.onClick}>
            <div className="uibutton__content">
                <span className="uibutton__text text">
                    {props.children}
                </span>
            </div>
        </button>
    )
}
