import './App.css'
import UIButton from './UIButton'
import RowModule from "./RowModule"
import UICounter from './UICounter'
import { useState } from 'react'

function App() {
  const [counter, setCounter] = useState(0)
  const [verify, setVerify] = useState(false)
  function updateCounter(number) {
    setCounter(counter+number)
    console.debug("counter: "+counter)
  }
  function resetCounter(e) {
    if(verify){
      setCounter(0)
      console.debug("counter reset!")
      setVerify(false)
    }else if(counter===0){
      return
    }else{
      console.debug(e)
      setVerify(true)
    }
  }
  return (
    <div className="App">
      <header className="App-header">
        <div className='flex-container'>
          <RowModule>
            <UICounter>{counter}</UICounter>
          </RowModule>
          <RowModule>
            <UIButton onClick={()=>updateCounter(1)}>Bus Stop 🚏</UIButton>
          </RowModule>
          <RowModule>
            <UIButton onClick={()=>updateCounter(3)}>Double!! 🚏</UIButton>
          </RowModule>
          <RowModule>
            <UIButton onClick={()=>resetCounter()}>{verify ? "ARE YOU SURE?":"⚠️ Reset ⚠️"}</UIButton>
          </RowModule>
        </div>
      </header>
    </div>
  )
}

export default App
